import React from "react";
import { Solution } from "../../views/About";
import { IconArrowBtn } from "../../layouts/Header";
import { ButtonPrimary } from "../Buttons/ButtonPrimary";
import { SolutionCard } from "./styles";

const CardSolution = ({
  name,
  to,
  icon,
  description,
  button,
  active,
  setActive,
}: Solution) => {
  return (
    <SolutionCard
      onClick={() => {
        if (button) {
          const solutionactive = {
            icon,
            name,
            description,
            to,
            button,
            active,
            setActive,
          };
          setActive?.(solutionactive);
          document
              .getElementById("solution-item")
              ?.scrollIntoView({ behavior: "smooth" });
        }
      }}
      className={
        button
          ? active?.name === name
            ? "back-second hover-back-second solution-button active"
            : "hover-back-second solution-button"
          : "hover-back-primary"
      }
    >
      {icon}
      <h1>{name}</h1>
      {!button ? <p>{description}</p> : ""}

      {!button ? (
        <ButtonPrimary
          onClick={() => {
            const solutionactive = {
              icon,
              name,
              description,
              to,
              button,
              active,
              setActive,
            };
            setActive?.(solutionactive);
            document
              .getElementById("solutions")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Saiba Mais <IconArrowBtn />
        </ButtonPrimary>
      ) : (
        <div>
          <IconArrowBtn />
        </div>
      )}
    </SolutionCard>
  );
};

export default CardSolution;
