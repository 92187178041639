import styled from "styled-components";

export const ContainerStepsConstruction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TextView = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: var(--color-text-1);
  text-align: center;
  @media screen and (max-width: 1335px) {
    font-size: 28px;
  }
  @media screen and (max-width: 915px) {
    font-size: 25px;
  }
  @media screen and (max-width: 750px) {
    font-size: 22px;
  }
`;
export const DescriptionView = styled.p`
  font-size: 22px;
  font-weight: 400;
  margin: 10px 0;
  color: var(--color-text-3);
  text-align: center;
  > b {
    position: relative;
  }
  svg {
    margin: 0 2px;
    position: relative;
    top: 5px;
    path {
      stroke: var(--color-text-3);
    }
  }
  @media screen and (max-width: 1335px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1335px) {
    font-size: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 750px) {
    font-size: 14px;
    margin: 5px 0;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const StepContent = styled.section`
  margin: 80px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  > div:nth-child(2) {
    margin-top: 0;
  }
  > div:nth-last-child(1) {
    margin-bottom: -20px;
  }
`;

export const StepPercentHeight = styled.div`
  position: absolute;
  height: 100%;
  width: 25px;
  height: 100%;
  background-color: #f2f2f2;
  z-index: -1;
  border-radius: 12px;
  > div {
    border-radius: 12px;
    background-color: var(--color-primary);
  }
  @media screen and (max-width: 750px) {
    width: 22px;
  }
  @media screen and (max-width: 640px) {
    width: 20px;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  /* width: 100%; */
  margin: 3.5% 0;
  @media screen and (max-width: 490px) {
    margin: 7% 0;
  }
  > section {
    position: relative;
    display: flex;
    align-items: center;
  }
  > section > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 20px;
    color: var(--color-text-3);
    font-weight: 700;
    background-color: #f2f2f2;
    border-radius: 100%;
    > svg {
      width: 30px;
      height: 30px;
      path {
        stroke-width: 3px;
        stroke: var(--color-text-3);
      }
    }
  }
  &.active > section > div {
    background-color: var(--color-primary);
    color: #fff;
    > svg path {
      stroke: #fff;
    }
  }
  &.active {
    transform: scale(1.1);
  }
  &.active > section > p {
    color: var(--color-primary);
  }
  > section > p {
    position: absolute;
    width: 300px;
    font-size: 17px;
    font-weight: 500;
    color: var(--color-text-3);
    &.left {
      right: 90px;
      text-align: right;
    }
    &.right {
      left: 90px;
      text-align: left;
    }
  }
  @media screen and (max-width: 1335px) {
    > section > p {
      width: 280px;
      font-size: 14px;
      &.left {
        right: 80px;
      }
      &.right {
        left: 80px;
      }
    }
    > section > div {
      width: 50px;
      font-size: 17px;
      height: 50px;
      > svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    > section > p {
      width: 220px;
      font-size: 12px;
      &.left {
        right: 70px;
      }
      &.right {
        left: 70px;
      }
    }
    > section > div {
      width: 45px;
      font-size: 16px;
      height: 45px;
    }
  }
  @media screen and (max-width: 640px) {
    > section > p {
      width: 120px;
      font-size: 12px;
      &.left {
        right: 60px;
      }
      &.right {
        left: 60px;
      }
    }
    > section > div {
      width: 42px;
      font-size: 15px;
      height: 42px;
    }
  }
`;
