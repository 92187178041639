import React, { useEffect } from "react";
import { ContentView } from "../../styles";
import {
  ContainerSolutions,
  SolutionsContentButtons,
  SolutionsContentItem,
} from "./style";
import { DescriptionView, TextView } from "../StepsConstruction/style";
import { IconHome, solutions } from "./mock";
import CardSolution from "../../components/About/CardSolution";
import { ButtonPrimary } from "../../components/Buttons/ButtonPrimary";
import { IconArrowBtn } from "../../layouts/Header";
import { Solution } from "../About";
import ItemSolution from "../../components/Solutions/ItemSolution";
import { onSolicitarOrcamento } from "../../providers/infos";

const Solutions = ({
  activeSolution,
  setActiveSolution,
}: {
  activeSolution: Solution;
  setActiveSolution: React.Dispatch<React.SetStateAction<Solution>>;
}) => {
  useEffect(() => {
    console.log(activeSolution);
  }, [activeSolution]);

  return (
    <ContentView>
      <ContainerSolutions id="solutions">
        <TextView>Nossas Soluções</TextView>
        <DescriptionView>
          Maximize seus ganhos: invista em{" "}
          <b>
            <IconHome />
            energia solar!
          </b>{" "}
          Ganhe mais de 25% ao ano com nossa gama completa de soluções. Descubra
          agora!
        </DescriptionView>
        <ButtonPrimary onClick={() => onSolicitarOrcamento()}>
          Solicite um orçamento <IconArrowBtn />
        </ButtonPrimary>

        <SolutionsContentButtons>
          {solutions?.map((solution) => (
            <CardSolution
              icon={solution?.icon}
              name={solution?.name}
              description={solution.description}
              to={solution?.to}
              button
              active={activeSolution}
              setActive={setActiveSolution}
            />
          ))}
          <div id="solution-item"></div>
        </SolutionsContentButtons>

        {activeSolution && <ItemSolution activeSolution={activeSolution} />}
      </ContainerSolutions>
    </ContentView>
  );
};

export default Solutions;
