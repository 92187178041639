import styled from "styled-components";

export const ContainerFooter = styled.div`
  .clinit-start--back-bottom {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
  }

  .clinit-start--contacts {
    display: flex;
    padding: var(--padding-page);
    margin-bottom: -150px;
    z-index: 4;
    width: 100%;
  }

  .clinit-start--contacts > section {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 22px;
    width: 100%;
    justify-content: center;
    height: auto;
    padding: 30px;

    &::after {
      background-color: var(--color-primary) !important;
    }
    &::before {
      background-color: var(--color-second) !important;
    }
  }

  .clinit-start--contacts-item {
    margin: 0 10px;
    border: 2px solid var(--color-third);
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 25px;
    width: 100%;
    height: 150px;
    padding-top: 5px;
    text-align: left;
    border-radius: 22px;
    cursor: pointer;
    padding-bottom: 35px;
  }

  .clinit-start--contacts-item:hover {
    border-bottom: 2px solid var(--color-primary);
    border-top: 2px solid var(--color-second);
    border-right: 2px solid var(--color-for);
    background-color: var(--color-primary-20);
  }

  .clinit-start--contacts-item:active {
    transform: scale(0.98);
  }

  .clinit-start--contacts-item > svg {
    min-width: 45px;
    min-height: 45px;
  }

  .clinit-start--contacts-item > svg path {
    stroke: var(--color-third);
  }

  .clinit-start--contacts-item > svg path:nth-child(2) {
    stroke: var(--color-primary);
  }

  .clinit-start--contacts-item > div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 50px;
    margin-left: 10px;
    text-align: left;
  }

  .clinit-start--contacts-item > div > p {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-third);
  }

  .clinit-start--contacts-item > div > h5 {
    color: var(--color-text-2);
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
  }

  /* FOOTER */

  .clinit-start--footer {
    background-color: var(--color-third);
    /* height: 400px; */
    padding-top: 270px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .clinit-start--footer-content {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-page);
    width: 100%;
  }

  .clinit-start--footer-bottom {
    width: 100%;
    height: 80px;
    margin-top: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clinit-start--footer-bottom > section {
    flex: 2;
    height: 1px;
    background-color: #ffffff7e;
  }

  .clinit-start--footer-bottom > p {
    color: #fff;
    font-weight: 500;
    margin: 0 20px;
    font-size: 14px;
  }

  .clinit-start--footer-bottom > p:hover {
    color: #ffffffc6;
  }

  .clinit-start--footer-content-left > img {
    width: auto;
  }

  .clinit-start--footer-content-left > div {
    display: flex;
    margin: 40px 0;
    align-items: center;
    width: 300px;
  }

  .clinit-start--footer-content-left > div > svg {
    width: 25px;
    height: 25px;
    min-width: 25px;
    cursor: pointer;
    min-height: 25px;
    margin-right: 12px;
  }

  .clinit-start--footer-content-left > div > svg path {
    stroke: #fff;
  }

  .clinit-start--footer-content-left > div > p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration-color: var(--color-primary);
  }

  .clinit-start--footer-content-left > div > p:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: pointer;
    text-decoration-color: var(--color-primary);
  }

  .clinit-start--footer-content-links {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
  }

  @media screen and (max-width: 1335px) {
    .clinit-start--contacts-item {
      padding: 25px;
      height: 125px;
    }
    .clinit-start--contacts-item > svg {
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
    }

    .clinit-start--contacts-item > div {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      height: auto;
      margin-left: 10px;
    }

    .clinit-start--contacts-item > div > p {
      font-size: 15px;
    }

    .clinit-start--contacts-item > div > h5 {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 1120px) {
    .clinit-start--contacts > section {
      flex-direction: column;
      height: auto;
      padding: 30px 15px;
    }
    .clinit-start--contacts-item {
      margin: 0;
      width: 95%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .clinit-start--contacts {
      margin-bottom: -250px;
    }

    .clinit-start--footer {
      height: 700px;
    }
    .clinit-start--footer-content {
      padding-top: 110px;
    }
  }

  @media screen and (max-width: 915px) {
    .clinit-start--footer-bottom > p {
      margin: 0 0px;
      font-size: 12px;
    }
    .clinit-start--footer-bottom {
      height: 70px;
    }
  }

  @media screen and (max-width: 640px) {
    .clinit-start--contacts > section {
      padding: 15px 10px;
      margin: 0 auto;
      width: 92%;
    }
    .clinit-start--footer-bottom > p {
      margin: 0 0px;
      font-size: 10px;
    }
  }

  @media (max-width: 490px) {
    .clinit-start--footer-content-left > img {
      width: 170px;
    }
    .clinit-start--footer-content-left > div {
      margin: 27px 0;
      width: 230px;
    }

    .clinit-start--footer-content-left > div > svg {
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
      margin-right: 12px;
    }
    .clinit-start--footer-content-left > div > p {
      font-size: 11px;
    }
    .clinit-start--footer-bottom {
      height: 70px;
    }
    .clinit-start--footer {
      height: 670px;
    }
    .clinit-start--footer-content {
      padding-top: 90px;
    }
    /*  */

    .clinit-start--contacts > section {
      padding: 15px;
    }

    .clinit-start--contacts-item {
      margin: 5px 0px;
      padding: 14px;
      width: 100%;
      height: 110px;
      padding-bottom: 14px;
    }

    .clinit-start--contacts-item > svg {
      min-width: 34px;
      min-height: 34px;
      width: 34px;
      height: 34px;
    }
    .clinit-start--contacts-item > div {
      justify-content: center;
      align-items: start;
      height: 100%;
      margin-left: 8px;
    }
    .clinit-start--contacts-item > div > p {
      font-size: 13px;
    }
    .clinit-start--contacts-item > div > h5 {
      font-size: 11px;
    }
  }
`;

export const AreaSocial = styled.section`
  display: flex;
  align-items: center;
  > svg:nth-child(1) {
    path {
      stroke: #fff;
      fill: var(--color-third);
      stroke-width: 1.5px;
    }
  }
  > svg {
    width: 32px;
    height: 32px;
    background-color: #fff;
    padding: 5px;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 8px;
    path {
      stroke: var(--color-third);
      stroke-width: 2px;
    }
    &:hover {
      transform: scale(1.3);
    }
  }
  > p {
    color: #fff;
  }
  @media (max-width: 490px) {
    > svg {
      width: 30px;
      height: 30px;
      padding: 5px;
    }
    > p {
      font-size: 14px;
    }
  }
`;
