import styled from "styled-components";

export const ContainerPortfolio = styled.div`
  margin: 80px 0;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  > button {
    margin: 20px 0;
  }

  .swiper {
    width: 100%;
    padding-top: 50px;
    /* height: 550px; */
    padding-bottom: 100px;
    /* height: 400px; */
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    /* width: 300px; */
    /* height: 700px; */
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    /* left: -12%; */
    position: relative;
  }
  .swiper-pagination-bullet {
    width: 7px !important;
    height: 7px !important;
    background-color: var(--color-text-3);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--color-second);
  }

  .swiper-slide {
    filter: grayscale(0.9);
  }

  .swiper-slide-active {
    filter: grayscale(0);
    img {
      transform: scale(1.15);
      @media (max-width: 600px) {
        transform: scale(1);
      }
    }
  }

  @media (max-width: 700px) {
    .swiper {
      padding-bottom: 0px;
    }
    .swiper {
      padding-bottom: 80px;
    }
    .swiper-slide img {
      width: 100%;
      left: 0%;
    }
  }
  @media (max-width: 490px) {
    .swiper {
      padding-bottom: 60px;
    }
  }
`;
