import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-page);
`;
export const ContentView2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 0 14%; */
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
