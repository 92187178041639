import React from "react";
import logoAllWhite from "../../assets/images/logos/white_logo.png";
import {
  email,
  linkFacebook,
  linkInstagram,
  linkLocal,
  linkWhatsapp,
  linkWhatsapp2,
  local,
  nav,
  telefone,
  telefone2,
} from "../../providers/infos";
import { NavLinks } from "../Header/styles";
import { LinkButton } from "../../components/Buttons/LinkButton";
import { HeaderTypes } from "../Header";
import { AreaSocial, ContainerFooter } from "./styles";
import { ContentView2 } from "../../styles";

function Footer({ activeLink, setActiveLink }: HeaderTypes) {
  return (
    <ContentView2>
      <ContainerFooter>
        <div id="contacts" className="clinit-start--back-bottom">
          <div className="clinit-start--contacts">
            <section className="back-primary">
              <div
                style={{ cursor: "auto" }}
                className="clinit-start--contacts-item"
              >
                <IconWhatsApp />
                <div>
                  <p>Ligue ou mande uma mensagem</p>
                  <h5 style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{ cursor: "pointer", fontSize: 18 }}
                      onClick={() => window.open(linkWhatsapp)}
                      className="hover-text"
                    >
                      {telefone}
                    </p>
                    <p
                      style={{ cursor: "pointer", fontSize: 18, marginTop: 5 }}
                      onClick={() => window.open(linkWhatsapp2)}
                      className="hover-text"
                    >
                      {telefone2}
                    </p>
                  </h5>
                </div>
              </div>
              <div
                style={{ cursor: "auto" }}
                className="clinit-start--contacts-item"
              >
                <IconCalendar className={"icon-calendar-footer"} />
                <div>
                  <p>Estamos abertos de</p>
                  <h5>Segunda-Sexta, 7:00 - 16:00</h5>
                </div>
              </div>
              <div
                onClick={() => window.open(`mailto:${email}`)}
                className="clinit-start--contacts-item"
              >
                <IconEmail />
                <div>
                  <p>Envie-nos um e-mail</p>
                  <h5>{email}</h5>
                </div>
              </div>
            </section>
          </div>
          <div className="clinit-start--footer">
            <section className="clinit-start--footer-content">
              <div className="clinit-start--footer-content-left">
                <img
                  src={logoAllWhite}
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  style={{ cursor: "pointer" }}
                  alt="logo Start"
                />
                <div>
                  <IconLocationNormal onClick={() => window.open(linkLocal)} />
                  <p onClick={() => window.open(linkLocal)}>{local}</p>
                </div>
                <AreaSocial>
                  <IconFacebook onClick={() => window.open(linkFacebook)} />
                  <IconInstagram onClick={() => window.open(linkInstagram)} />
                  <p>
                    @<b>griztechs</b>solucoes
                  </p>
                </AreaSocial>
              </div>
              <div className="clinit-start--footer-content-links">
                <NavLinks className="flex-column">
                  {nav.map((link) => (
                    <LinkButton
                      id={`${link?.id}`}
                      to={link?.to}
                      className={activeLink === link?.id ? "active" : ""}
                      onClick={() => {
                        setActiveLink(link?.id);
                        window.scrollTo({ top: 0 });
                      }}
                      spy={true}
                      smooth={true}
                      offset={-120}
                      delay={0}
                      duration={20}
                    >
                      {link?.name}

                      {activeLink === link?.id ? <section></section> : ""}
                    </LinkButton>
                  ))}
                </NavLinks>
              </div>
            </section>
            <section className="clinit-start--footer-bottom">
              {/* <div
            style={{ bottom: 15 }}
            className="clinit-start--details-img-left"
          ></div> */}
              <section />
              <p>
                © {new Date()?.getFullYear()}{" "}
                <b>Griz Techs Solucoes Solar</b> LTDA.
                All Rights Reserved.
              </p>
              <section />
              {/* <div
            style={{ bottom: 15 }}
            className="clinit-start--details-img-right"
          ></div> */}
            </section>
          </div>
        </div>
      </ContainerFooter>
    </ContentView2>
  );
}

export default Footer;

const IconFacebook = ({ ...a }) => (
  <svg
    width="24"
    {...a}
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

const IconLocationNormal = ({ ...a }) => (
  <svg
    {...a}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 9C14.5 10.3807 13.3807 11.5 12 11.5C10.6193 11.5 9.5 10.3807 9.5 9C9.5 7.61929 10.6193 6.5 12 6.5C13.3807 6.5 14.5 7.61929 14.5 9Z"
      stroke="#141B34"
      stroke-width="1.5"
    />
    <path
      d="M13.2574 17.4936C12.9201 17.8184 12.4693 18 12.0002 18C11.531 18 11.0802 17.8184 10.7429 17.4936C7.6543 14.5008 3.51519 11.1575 5.53371 6.30373C6.6251 3.67932 9.24494 2 12.0002 2C14.7554 2 17.3752 3.67933 18.4666 6.30373C20.4826 11.1514 16.3536 14.5111 13.2574 17.4936Z"
      stroke="#141B34"
      stroke-width="1.5"
    />
    <path
      d="M18 20C18 21.1046 15.3137 22 12 22C8.68629 22 6 21.1046 6 20"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

const IconInstagram = ({ ...a }) => (
  <svg
    {...a}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
      stroke="#141B34"
      stroke-width="1.5"
    />
    <path
      d="M17.5078 6.5L17.4988 6.5"
      stroke="#141B34"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconWhatsApp = ({ ...a }) => (
  <svg
    {...a}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.3789 2.27907 14.6926 2.78382 15.8877C3.06278 16.5481 3.20226 16.8784 3.21953 17.128C3.2368 17.3776 3.16334 17.6521 3.01642 18.2012L2 22L5.79877 20.9836C6.34788 20.8367 6.62244 20.7632 6.87202 20.7805C7.12161 20.7977 7.45185 20.9372 8.11235 21.2162C9.30745 21.7209 10.6211 22 12 22Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M8.58815 12.3773L9.45909 11.2956C9.82616 10.8397 10.2799 10.4153 10.3155 9.80826C10.3244 9.65494 10.2166 8.96657 10.0008 7.58986C9.91601 7.04881 9.41086 7 8.97332 7C8.40314 7 8.11805 7 7.83495 7.12931C7.47714 7.29275 7.10979 7.75231 7.02917 8.13733C6.96539 8.44196 7.01279 8.65187 7.10759 9.07169C7.51023 10.8548 8.45481 12.6158 9.91948 14.0805C11.3842 15.5452 13.1452 16.4898 14.9283 16.8924C15.3481 16.9872 15.558 17.0346 15.8627 16.9708C16.2477 16.8902 16.7072 16.5229 16.8707 16.165C17 15.8819 17 15.5969 17 15.0267C17 14.5891 16.9512 14.084 16.4101 13.9992C15.0334 13.7834 14.3451 13.6756 14.1917 13.6845C13.5847 13.7201 13.1603 14.1738 12.7044 14.5409L11.6227 15.4118"
      stroke="#141B34"
      stroke-width="1.5"
    />
  </svg>
);

const IconCalendar = ({ ...a }) => (
  <svg
    {...a}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2V4M6 2V4"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9955 13H12.0045M11.9955 17H12.0045M15.991 13H16M8 13H8.00897M8 17H8.00897"
      stroke="#141B34"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.5 8H20.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 8H21"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const IconEmail = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7.5L9.94202 9.23943C11.6572 10.2535 12.3428 10.2535 14.058 9.23943L17 7.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 19.5C10.0337 19.4939 9.56682 19.485 9.09883 19.4732C5.95033 19.3941 4.37608 19.3545 3.24496 18.2184C2.11383 17.0823 2.08114 15.5487 2.01577 12.4814C1.99475 11.4951 1.99474 10.5147 2.01576 9.52843C2.08114 6.46113 2.11382 4.92748 3.24495 3.79139C4.37608 2.6553 5.95033 2.61573 9.09882 2.53658C11.0393 2.4878 12.9607 2.48781 14.9012 2.53659C18.0497 2.61574 19.6239 2.65532 20.755 3.79141C21.8862 4.92749 21.9189 6.46114 21.9842 9.52844C21.9939 9.98251 21.9991 10.1965 21.9999 10.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 17C19 17.8284 18.3284 18.5 17.5 18.5C16.6716 18.5 16 17.8284 16 17C16 16.1716 16.6716 15.5 17.5 15.5C18.3284 15.5 19 16.1716 19 17ZM19 17V17.5C19 18.3284 19.6716 19 20.5 19C21.3284 19 22 18.3284 22 17.5V17C22 14.5147 19.9853 12.5 17.5 12.5C15.0147 12.5 13 14.5147 13 17C13 19.4853 15.0147 21.5 17.5 21.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
