import styled from "styled-components";

export const ContainerVantagens = styled.div`
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 80px;
  background-color: var(--color-primary-50);
  width: 100%;
  position: relative;
  /* box-shadow: var(--box-shadow); */
  @media screen and (max-width: 1335px) {
    height: 250px;
  }
  @media screen and (max-width: 915px) {
    height: 220px;
  }
  @media screen and (max-width: 750px) {
    height: 200px;
  }
  @media screen and (max-width: 490px) {
    height: auto;
  }
`;
export const BackImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  opacity: 0.78;
  background-color: #269aff;
  position: absolute;
  object-fit: cover;
`;

export const VantagensItens = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #269aff47;
  width: 100%;
  z-index: 1;
  height: 100%;
  gap: 8px;
  padding: 32px;
  @media screen and (max-width: 915px) {
    background-color: #269aff60;
  }
  @media screen and (max-width: 490px) {
    flex-wrap: wrap;
  }
`;

export const VantagemItem = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #c7c7c76a; */
  /* backdrop-filter: blur(10px); */
  border-radius: 20px;
  position: relative;
  padding: 12px;
  flex-direction: column;
  > svg {
    width: 80px;
    height: 80px;
    > path {
      stroke: #fff;
      fill: #ffffff5e;
      stroke-width: 1.3px;
    }
  }
  > p {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-top: 8px;
    font-weight: 600;
  }
  > div {
    position: absolute;
    top: 20px;
    > svg {
      width: 30px;
      height: 30px;
      > path {
        stroke: #fff;
        fill: #ffffff5e;
      }
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
  @media screen and (max-width: 1335px) {
    > svg {
      width: 60px;
      height: 60px;
    }
    > p {
      font-size: 16px;
      margin-top: 12px;
    }
    > div {
      top: 10px;
      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media screen and (max-width: 915px) {
    > svg {
      width: 50px;
      height: 50px;
    }
    > p {
      font-size: 15px;
      margin-top: 10px;
    }
    > div {
      top: 5px;
      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 5px;
    > svg {
      width: 42px;
      height: 42px;
    }
    > p {
      font-size: 13px;
      margin-top: 10px;
    }
    > div {
      top: 0px;
      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media screen and (max-width: 640px) {
    padding: 2px;
    > svg {
      width: 38px;
      height: 38px;
    }
    > p {
      font-size: 11px;
      margin-top: 10px;
    }
    > div {
      > svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  @media screen and (max-width: 490px) {
    padding: 0px;
    > svg {
      width: 35px;
      height: 35px;
    }
    > p {
      font-size: 10px;
      margin-top: 5px;
    }
    > div {
      top: 2px;
      > svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
