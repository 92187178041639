import styled from "styled-components";
import { gradientlinear } from "../Banner/styles";

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    10deg,
    rgba(240, 115, 2, 0.8),
    rgba(240, 115, 2, 0.4),
    rgba(240, 115, 2, 0.8),
    rgba(240, 115, 2, 0.4)
  );
  background-size: 400% 400%;
  animation: ${gradientlinear} 10s ease infinite;
  backdrop-filter: saturate(180%) blur(20px);
  height: 90px;
  padding: 0 3%;
  z-index: 999;

  .show-in-responsive-750 {
    display: none;
  }
  @media screen and (max-width: 1335px) {
    height: 85px;
    padding: 0 2%;
  }
  @media screen and (max-width: 915px) {
    height: 75px;
    padding: 0 3%;
    justify-content: space-between;
    .show-in-responsive-750 {
      display: flex;
    }
    .show-out-responsive-750 {
      display: none;
    }
  }
  @media screen and (max-width: 750px) {
    height: 65px;
    padding: 0 2%;
  }
  @media screen and (max-width: 490px) {
    height: 65px;
    padding: 0 4%;
  }
`;

export const Logo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 200px;
    object-fit: cover;
    @media screen and (max-width: 1335px) {
      width: 150px;
    }
    @media screen and (max-width: 915px) {
      width: 120px;
    }
    @media screen and (max-width: 490px) {
      width: 110px;
    }
  }
`;

export const NavLinks = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 13%;
  width: 40%;

  &.flex-column {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    > a {
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1335px) {
    margin: 0 12%;
    width: 37%;
  }
  @media screen and (max-width: 915px) {
    margin: 0 2%;
    width: 95%;
  }
`;

export const ButtonMenu = styled.div`
  margin-left: 15px;
  > svg {
    width: 27px;
    height: 27px;
    path {
      stroke: #fff;
      stroke-width: 1.3px;
    }
  }
  @media screen and (max-width: 490px) {
    margin-left: 10px;
  }
`;
export const AreaButtonClose = styled.div`
  position: fixed;
  right: 3%;
  top: 25px;
  width: 42px;
  height: 32px;
  display: flex;
  align-items: center;
  z-index: 99999;
  justify-content: center;
`;
export const MenuArea = styled.div`
  position: fixed;
  visibility: hidden;
  opacity: 0;
  width: 230px;
  right: 3%;
  height: 250px;
  border-radius: 20px 5px 20px 20px;
  top: 22px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-second);
  & .nav-responsive {
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    > a {
      color: #ffffffb3;
      font-size: 20px;
      font-weight: 600;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      &.active {
        color: #fff;
        font-weight: 800;
        transform: scale(1);
        > section {
          width: 3px;
          height: 22px;
          background-color: var(--color-primary);
          position: absolute;
          left: 0px;
        }
      }
    }
  }
`;
