import styled from "styled-components";
import { gradientlinear } from "../../layouts/Banner/styles";

export const ButtonPrimary = styled.button`
  /* background-color: var(--color-primary); */
  /* color: #101113; */
  color: #fff;
  background: linear-gradient(
    90deg,
    var(--color-primary),
    rgba(240, 125, 2, 1),
    var(--color-primary),
    rgba(240, 125, 2, 1)
  );
  background-size: 400% 400%;
  animation: ${gradientlinear} 15s ease infinite;
  /* backdrop-filter: blur(10px); */
  border: none;
  border: 2px solid #ffffff4d;
  outline: none;
  padding: 0 20px;
  padding-right: 10px;
  height: 42px;
  font-size: 13px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1);
    box-shadow: var(--text-shadow);
  }
  &:active {
    transform: scale(1);
    > svg {
      transform: translateX(12px);
    }
  }
  > svg {
    margin-left: 2px;
    path {
      stroke: #fff;
    }
  }
  @media screen and (max-width: 1335px) {
    padding: 0 22px !important;
    padding-right: 10px !important;
    height: 40px !important;
    font-size: 12px !important;
  }
  @media screen and (max-width: 915px) {
    padding: 0 19px !important;
    padding-right: 8px !important;
    height: 37px !important;
    font-size: 11px !important;
    white-space: nowrap;
    border-radius: 20px;
    > svg {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 490px) {
    padding: 0 18px !important;
    padding-right: 7px !important;
    height: 35px !important;
    font-size: 10px !important;
    white-space: nowrap;
    border-radius: 20px;
    > svg {
      width: 18px;
      height: 18px;
    }
  }
`;
