import React, { useState } from "react";
import CardSolution from "../../components/About/CardSolution";
import { ContentView } from "../../styles";
import {
  CardInfo,
  ContainerAbout,
  ContentAbout,
  SolutionsContent,
} from "./styles";
import imgpainelsolar from "../../assets/images/painelsolar.webp";
import imgernegiasolar from "../../assets/images/ernegiasolar.png";
import { ButtonPrimary } from "../../components/Buttons/ButtonPrimary";
import { IconArrowBtn } from "../../layouts/Header";
import { LinkButton } from "../../components/Buttons/LinkButton";
import { solutions } from "../Solutions/mock";

export interface Solution {
  icon: JSX.Element;
  name: string;
  description: string;
  to: string;
  button?: boolean;
  active?: Solution;
  setActive?: React.Dispatch<React.SetStateAction<Solution>>;
}

function About({
  activeSolution,
  setActiveSolution,
}: {
  activeSolution: Solution;
  setActiveSolution: React.Dispatch<React.SetStateAction<Solution>>;
}) {
  const valores = [
    {
      icon: <IconGlobal className="icon-world" />,
      title: "Missão",
      description:
        "Nosso compromisso é liderar a expansão da energia solar no Brasil, impulsionando a excelência em nossos negócios. Priorizamos a satisfação de nossos clientes, contando com uma equipe altamente qualificada e fazendo uso de tecnologia de ponta.",
    },
    {
      icon: <IconSolarPower className="icon-solar" />,
      title: "Visão",
      description:
        "Referência nacional em energia renovável, buscando soluções fotovoltaicas com ética profissional, qualidade de vida e excelência em nossos serviços.",
    },
    {
      icon: <IconSolarEnergy className="icon-energy" />,
      title: "Valores",
      description:
        "Responsabilidade e integridade, confiança e qualidade. Foco no cliente com excelência, profissionalismo surpreendente, sustentabilidade econômica, social e ambiental.",
    },
  ];

  return (
    <ContentView>
      <ContainerAbout>
        <SolutionsContent>
          {solutions?.map((solution) => (
            <CardSolution
              icon={solution?.icon}
              name={solution?.name}
              description={solution.description}
              to={solution?.to}
              active={activeSolution}
              setActive={setActiveSolution}
            />
          ))}
        </SolutionsContent>
        <ContentAbout id="about">
          <section>
            <div className="area-text">
              <p>Quem Somos</p>
              {/* <h2>
                A <b>GrizTech's</b> <strong>Soluções Solar</strong> é uma empresa que atua na área de
                Engenharia Elétrica, com destaque na especialização em Energia
                Fotovoltaica.
              </h2> */}
              <h2>
                A GrizTech's Soluções Solar é uma empresa que atua na área de
                Engenharia Elétrica, com destaque na especialização em Energia
                Fotovoltaica.
              </h2>
            </div>
            <CardInfo style={{ flexDirection: "row" }}>
              <div>
                <h5>
                  Com um amplo portfólio de usinas em diversos segmentos, a
                  GrizTech oferece serviços completos, incluindo Projeto,
                  Construção, Instalação, Manutenção e Monitoramento de Sistemas
                  Fotovoltaicos, garantindo qualidade através de nossa equipe
                  técnica certificada e em constante aperfeiçoamento.
                </h5>
                <LinkButton
                  id={`3`}
                  to={"portfolio"}
                  className={"button"}
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                  spy={true}
                  smooth={true}
                  offset={-40}
                  delay={0}
                  duration={20}
                >
                  Ver nosso Portifólio <IconArrowBtn />
                </LinkButton>
              </div>

              <img src={imgpainelsolar} alt="Painel Solar" />
              <IconHome2 />
            </CardInfo>
          </section>
          <section>
            {valores?.map((valor) => (
              <CardInfo className="hover-back-second">
                <h1>{valor?.title}</h1>
                <p>{valor?.description}</p>
                {valor?.icon}
              </CardInfo>
            ))}
          </section>
        </ContentAbout>
      </ContainerAbout>
    </ContentView>
  );
}

export default About;
export const IconSolarEnergy = ({ ...r }) => (
  <svg
    {...r}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9999 15.9988C16.4999 16.999 14.6842 18.9772 14 22M15 19.3372C12.6321 14.0106 17.4087 12.2141 21.0443 12.0143C21.4831 11.9902 21.7025 11.9782 21.86 12.1336C22.0176 12.289 22.0085 12.5106 21.9903 12.9536C21.8394 16.638 20.3286 21.707 15 19.3372Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 20H6.02099C3.73502 20 2.59203 20 2.15185 19.2655C1.71168 18.531 2.2625 17.5428 3.36416 15.5666L4.79185 13.0054C5.53109 11.6793 5.90071 11.0162 6.47924 11.0003C7.05776 10.9844 7.46426 11.6261 8.27725 12.9095L8.97855 14.0166"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 8.03384L8.99165 6.15531C10.4526 3.38777 11.1831 2.004 12.2929 2.00001C13.4027 1.99601 14.1419 3.37449 15.6204 6.13145L15.8647 6.58709C16.7821 8.29766 17.2407 9.15294 16.8728 9.68491C16.5048 10.2169 15.6024 10.0032 13.7975 9.5758L13.715 9.55628"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconSolarPower = ({ ...r }) => (
  <svg
    {...r}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.98915 15.3273L4.05648 12.3273C4.28468 11.6859 4.39879 11.3652 4.65689 11.1826C4.91498 11 5.25424 11 5.93275 11H18.0672C18.7458 11 19.085 11 19.3431 11.1826C19.6012 11.3652 19.7153 11.6859 19.9435 12.3273L21.0108 15.3273C21.4437 16.5438 21.6601 17.1521 21.3624 17.576C21.0648 18 20.4214 18 19.1346 18H4.86542C3.5786 18 2.93519 18 2.63756 17.576C2.33994 17.1521 2.55634 16.5438 2.98915 15.3273Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.5 11L8 18M14.5 11L16 18"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 14.5H20"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3333 5.5C14.3333 6.78866 13.2887 7.83333 12 7.83333M14.3333 5.5C14.3333 4.21134 13.2887 3.16667 12 3.16667M14.3333 5.5H15.5M12 7.83333C10.7113 7.83333 9.66667 6.78866 9.66667 5.5M12 7.83333V9M9.66667 5.5C9.66667 4.21134 10.7113 3.16667 12 3.16667M9.66667 5.5L8.5 5.5M12 3.16667V2M13.6499 3.85009L14.4749 3.02513M10.3501 7.14992L9.52513 7.97488M13.6499 7.14992L14.4749 7.97488M10.3501 3.85009L9.52513 3.02513"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 22C13.9398 20.8271 14 19 14 18M9 22C10.0602 20.8271 10 19 10 18"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M2 22H22"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconGlobal = ({ ...r }) => (
  <svg
    {...r}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M20 5.69899C19.0653 5.76636 17.8681 6.12824 17.0379 7.20277C15.5385 9.14361 14.039 9.30556 13.0394 8.65861C11.5399 7.6882 12.8 6.11636 11.0401 5.26215C9.89313 4.70542 9.73321 3.19045 10.3716 2"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M2 11C2.7625 11.6621 3.83046 12.2682 5.08874 12.2682C7.68843 12.2682 8.20837 12.7649 8.20837 14.7518C8.20837 16.7387 8.20837 16.7387 8.72831 18.2288C9.06651 19.1981 9.18472 20.1674 8.5106 21"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M22 13.4523C21.1129 12.9411 20 12.7308 18.8734 13.5405C16.7177 15.0898 15.2314 13.806 14.5619 15.0889C13.5765 16.9775 17.0957 17.5711 14 22"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconHome2 = () => (
  <svg
    className="icon-home"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 10C2.77968 6.18409 5.66918 3.12932 9.40618 2.08977C9.71987 2.00251 9.87671 1.95888 9.96119 2.05236C10.0457 2.14584 9.98344 2.30042 9.85897 2.60956L9 4.5M14 2C17.8159 2.77968 20.8707 5.66918 21.9102 9.40618C21.9975 9.71987 22.0411 9.87671 21.9476 9.96119C21.8542 10.0457 21.6996 9.98344 21.3904 9.85897L19.5 9M22 14C21.2203 17.8159 18.3308 20.8707 14.5938 21.9102C14.2801 21.9975 14.1233 22.0411 14.0388 21.9476C13.9543 21.8542 14.0166 21.6996 14.141 21.3904L15 19.5M10 22C6.18409 21.2203 3.12932 18.3308 2.08977 14.5938C2.00251 14.2801 1.95888 14.1233 2.05236 14.0388C2.14584 13.9543 2.30042 14.0166 2.60956 14.141L4.5 15"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M8.61288 11.6647L12.112 7.17326C12.3857 6.82199 12.8986 7.04062 12.8986 7.50853V10.985C12.8986 11.2653 13.0998 11.4925 13.3479 11.4925H15.0498C15.4364 11.4925 15.6425 12.0074 15.3871 12.3353L11.888 16.8267C11.6143 17.178 11.1014 16.9594 11.1014 16.4915V13.015C11.1014 12.7347 10.9002 12.5075 10.6521 12.5075H8.95018C8.56356 12.5075 8.35747 11.9926 8.61288 11.6647Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
