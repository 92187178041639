import styled, { keyframes } from "styled-components";

export const border = keyframes`
	0% {
        border: 2px solid var(--color-for-50);
	}
	50% {
        border: 2px solid var(--color-for);
	}
	100% {
        border: 2px solid var(--color-for-50);
	}
`;
export const IconButtonBottom = styled.div`
  position: fixed;
  right: 20px;
  width: 52px;
  height: 52px;
  
  background-color: #ffffffe3;
  /* backdrop-filter: saturate(180%) blur(20px); */
  border: 2px solid var(--color-primary);
  border-top: 2px solid var(--color-primary);
  border-bottom: 2px solid var(--color-second);
  border-radius: 100%;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  &:active {
    transform: scale(0.96);
  }
  > svg {
    width: 28px;
    height: 28px;
    path {
      stroke-width: 2px;
      stroke: var(--color-third);
    }
  }
  cursor: pointer;
  > p {
    position: absolute;
    width: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    justify-content: center;
    border: 2px solid var(--color-for-50);
    border-radius: 10px 10px 0 10px;
    padding: 5px 12px;
    background-color: #ffffffec;
    /* backdrop-filter:saturate(180%) blur(20px); */
    color: var(--color-for);
    font-weight: 500;
    box-shadow: var(--box-shadow);
    right: 55px;
    animation: ${border} 2s ease infinite;
    bottom: 20px;
  }
  @media screen and (max-width: 1335px) {
    right: 15px;
    width: 48px;
    height: 48px;
    > p {
    font-size: 12px;
    padding: 4px 10px;
    right: 50px;
  }
  }
  &.whats {
    border: 2px solid var(--color-for-50);
    > svg {
      width: 25px;
      height: 25px;
      path {
        stroke: var(--color-for);
      }
    }
    animation: ${border} 3s ease infinite;
  }
`;
