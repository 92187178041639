export const IconHome = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.96586 5.79755L4.56916 4.39672C5.06999 3.23383 5.32041 2.65238 5.81138 2.32619C6.30235 2 6.92711 2 8.17664 2H15.8234C17.0729 2 17.6976 2 18.1886 2.32619C18.6796 2.65238 18.93 3.23383 19.4308 4.39672L20.0341 5.79755C20.8508 7.69388 21.2592 8.64205 20.8221 9.32102C20.3849 10 19.3661 10 17.3285 10H6.67147C4.63387 10 3.61507 10 3.17794 9.32102C2.7408 8.64205 3.14915 7.69388 3.96586 5.79755Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.5 10V15C4.5 16.8856 4.5 17.8284 5.08579 18.4142C5.55733 18.8858 6.26022 18.9777 7.5 18.9957M19.5 10V15C19.5 16.8856 19.5 17.8284 18.9142 18.4142C18.4427 18.8858 17.7398 18.9777 16.5 18.9957"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 14L10.4058 17.0672C10.0891 17.4778 9.93078 17.6831 10.0289 17.8416C10.127 18 10.4124 18 10.9833 18H13.0167C13.5876 18 13.873 18 13.9711 18.1584C14.0692 18.3169 13.9109 18.5222 13.5942 18.9328L10.9833 22"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 2L9 10M14 2L15 10"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 6H20"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const IconGreenHouse = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.551 13.6081C11.0316 15.2407 5.21031 18.0107 7.02784 21.0275C7.68071 22.1111 9.56024 22 10.8612 22H13.1388C16.5537 22 18.2611 22 19.429 21.0286C20.5969 20.0572 20.8471 18.4289 21.3476 15.1724L21.6486 13.2135C22.0016 10.9162 22.1781 9.76763 21.7438 8.74938C21.3095 7.73112 20.346 7.03443 18.4189 5.64106L16.979 4.6C14.5817 2.86667 13.3831 2 12 2C10.6169 2 9.41829 2.86667 7.02099 4.6L5.58114 5.64106C3.65403 7.03443 2.69047 7.73112 2.25617 8.74938C1.82186 9.76763 1.99837 10.9162 2.35139 13.2135L2.65243 15.1724C3.00843 17.489 3.23781 18.9817 3.76102 20M12.1801 18.1259C14.5484 17.6674 16.1708 16.2098 16.9339 13.855C17.7983 11.1881 16.6267 7.88376 13.6752 7.11938C13.6752 7.11938 14.5852 8.20421 13.9973 9.15585C12.864 11.1955 9.62452 10.5174 8.32535 13.1165C7.56032 14.5675 7.79225 16.285 8.89383 17.3563C9.58602 18.0294 11.0428 18.3461 12.1801 18.1259Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconCompany = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 22V7.4849C21 6.38738 21 5.83862 20.6845 5.37551C20.3689 4.9124 19.7876 4.608 18.6251 3.99921L17.1459 3.22461C15.3209 2.26893 14.4084 1.7911 13.7042 2.08712C13 2.38315 13 3.24459 13 4.96746V9"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M22 22H2"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 8H19M21 11H19M21 14H19"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 13C8 11.1144 8 10.1716 8.58579 9.58579C9.17157 9 10.1144 9 12 9V9C13.8856 9 14.8284 9 15.4142 9.58579C16 10.1716 16 11.1144 16 13V22H8V13Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M11.5 13H12.5M11.5 16H12.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <ellipse
      cx="3.5"
      cy="14"
      rx="1.5"
      ry="2"
      stroke="#141B34"
      stroke-width="1.5"
    />
    <path
      d="M3.5 16V22"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export const IconCondominium = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2L2 7"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 3V22H7C5.11438 22 4.17157 22 3.58579 21.4142C3 20.8284 3 19.8856 3 18V7"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 7L22 12"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 21.9997H17C18.8856 21.9997 19.8284 21.9997 20.4142 21.4139C21 20.8281 21 19.8853 21 17.9997V11.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 10L18 7"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 11H8M7 15H8"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 14H17"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5 22V18"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const solutions = [
  {
    icon: <IconHome />,
    name: "Energia Solar Residencial",
    description:
      "Economize até 95% na conta de luz da sua residência. Vamos ajudá-lo a encontrar a melhor solução para gerar sua própria energia e reduzir os custos.",
    to: "/",
  },
  {
    icon: <IconCondominium />,
    name: "Energia Solar para Condomínio",
    description:
      "Investir em energia solar para o seu condomínio economiza até 95% na conta de luz.",
    to: "/",
  },
  {
    icon: <IconCompany />,
    name: "Energia Solar Empresarial",
    description:
      "Corte os impostos da sua empresa. Quase metade de sua conta de luz é composta por tarifas e impostos, e o autoconsumo pode eliminá-los.",
    to: "/",
  },
  {
    icon: <IconGreenHouse />,
    name: "Energia Solar Para Agronegócios",
    description:
      "Faça sua empresa mais sustentável, aumente o lucro e contribua para o meio ambiente com o controle de gastos e a construção de usinas solares.",
    to: "/",
  },
];
