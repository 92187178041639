export const email = "comercial@griztechsolucoessolar.com.br";
export const linkLocal =
  "https://www.google.com.br/maps/search/Rua%20Barbosa%20de%20Freitas%201741%20Aldeota%20Fortaleza%20CE";
export const linkWhatsapp = "https://wa.me/5585986514503";
export const linkWhatsapp2 = "https://wa.me/message/BAEI635UVXSPA1";
export const linkInstagram = "https://www.instagram.com/griztechssolucoes";
export const linkFacebook = "https://www.facebook.com/griztechssolucoes";
export const local =
  "Rua Barbosa de Freitas 1741 Sala 04 Aldeota Fortaleza CE 60170-021";
export const telefone = "(85) 98651-4503";
export const telefone2 = "(85) 99266-6044";

export const onSolicitarOrcamento = () => window.open(linkWhatsapp2);

export const nav = [
  {
    id: 0,
    to: "home",
    name: "Início",
  },
  {
    id: 1,
    to: "about",
    name: "Quem Somos",
  },
  {
    id: 2,
    to: "solutions",
    name: "Soluções",
  },
  {
    id: 3,
    to: "portfolio",
    name: "Portifólio",
  },
  {
    id: 4,
    to: "contacts",
    name: "Contatos",
  },
];
