import styled from "styled-components";

export const SolutionCard = styled.div`
  width: 320px;
  height: 340px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding: 28px;
  /* text-align: center; */
  gap: 10px;
  margin: 0 auto;
  > svg {
    width: 60px;
    height: 60px;
    > path,
    > ellipse {
      fill: var(--color-second-50);
      stroke: var(--color-second);
    }
  }
  > h1 {
    font-size: 19px;
    color: var(--color-text-1);
  }
  > p {
    font-size: 14px;
    color: var(--color-text-2);
  }
  > button {
    margin-top: auto;
    > svg {
      position: relative;
      left: 25%;
      margin: 0;
      @media screen and (max-width: 1335px) {
        left: 15%;
      }
    }
  }

  &.solution-button {
    /* height: 270px;
      width: 300px; */
    height: 280px;
    /* width: 320px; */
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    box-shadow: none;
    border: 3px solid var(--color-second-50);
    > svg {
      width: 70px;
      height: 80px;
      @media screen and (max-width: 1335px) {
        width: 60px;
        height: 70px;
      }
      @media screen and (max-width: 915px) {
        width: 50px;
        height: 60px;
      }
      @media screen and (max-width: 750px) {
        width: 40px;
        height: 50px;
      }
    }
    > div > svg {
      position: absolute;
      right: 22px;
      bottom: 22px;
      transform: rotate(90deg);
      path {
        stroke-width: 2.5px;
        stroke: var(--color-second-50);
      }
    }
    &.active::after {
      background: #fff;
      border: 3px solid var(--color-second-50);
    }
    &:active,
    &.active {
      /* height: 280px;
      width: 320px; */
      > svg {
        > path,
        > ellipse {
          fill: #fff5;
          stroke: #fff;
        }
      }
      background-color: var(--color-second-2);
      > h1 {
        color: #fff;
        transition: none;
      }
      > p {
        color: #fff;
        transition: none;
      }

      > div > svg path {
        stroke: #fff;
      }
    }
    > p {
      height: 40%;
    }
  }
  @media screen and (max-width: 1335px) {
    width: 300px;
    height: 320px;
    padding: 25px;
    gap: 8px;
    > svg {
      width: 57px;
      height: 57px;
    }
    > h1 {
      font-size: 16px;
    }
    > p {
      font-size: 12px;
    }
    &.solution-button {
      height: 230px;
    }
  }
  @media screen and (max-width: 915px) {
    width: 300px;
    height: 280px;
    padding: 20px;
    gap: 5px;
    > svg {
      width: 45px;
      height: 45px;
    }
    > h1 {
      font-size: 15px;
    }
    > p {
      font-size: 11px;
    }
    &.solution-button {
      height: 200px;
      > div > svg {
        right: 10px;
        bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    margin: 0;
    width: 280px;
    height: 280px;
    padding: 20px;
    gap: 5px;
    > svg {
      width: 50px;
      height: 50px;
    }
    > h1 {
      font-size: 15px;
    }
    > p {
      font-size: 11px;
    }
    &.solution-button {
      height: 150px;
      width: 170px;
      > div > svg {
        right: 5px;
        bottom: 5px;
      }
      > h1 {
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: 490px) {
    margin: 0;
    width: 100%;
    height: 250px;
    padding: 22px;
    gap: 5px;
    > svg {
      width: 60px;
      height: 60px;
    }
    > h1 {
      font-size: 15px;
    }
    > p {
      font-size: 12px;
    }
    &.solution-button {
      height: 150px;
      width: 160px;
      > h1 {
        font-size: 11px !important;
      }
      > div > svg {
        right: 7px;
        bottom: 7px;
        width: 20px;
        height: 20px;
      }
      > h1 {
        font-size: 13px;
      }
    }
  }
`;
