import React from "react";
import { ContentView, ContentView2 } from "../../styles";
import { TextView } from "../StepsConstruction/style";
import { ContainerPortfolio } from "./styles";
import { ButtonPrimary } from "../../components/Buttons/ButtonPrimary";
import { IconArrowBtn } from "../../layouts/Header";
import pdfFile from "../../assets/images/portifolio/portifoliogriztechsolar.pdf";
import img1 from "../../assets/images/portifolio/1.png";
import img2 from "../../assets/images/portifolio/2.png";
import img3 from "../../assets/images/portifolio/3.png";
import img4 from "../../assets/images/portifolio/4.png";
import img5 from "../../assets/images/portifolio/5.png";
import img6 from "../../assets/images/portifolio/6.png";
import img7 from "../../assets/images/portifolio/7.png";
import img8 from "../../assets/images/portifolio/8.png";
import img9 from "../../assets/images/portifolio/9.png";
import img10 from "../../assets/images/portifolio/10.png";
import img11 from "../../assets/images/portifolio/11.png";
import img12 from "../../assets/images/portifolio/12.png";
import img13 from "../../assets/images/portifolio/13.png";
import img14 from "../../assets/images/portifolio/14.png";
import img15 from "../../assets/images/portifolio/15.png";
import img16 from "../../assets/images/portifolio/16.png";
import img17 from "../../assets/images/portifolio/17.png";
import img18 from "../../assets/images/portifolio/18.png";
import img19 from "../../assets/images/portifolio/19.png";
import img20 from "../../assets/images/portifolio/20.png";
import img21 from "../../assets/images/portifolio/21.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

const Portfolio = () => {
  const getWidth = () => {
    if (window.matchMedia("(max-device-width: 960px)").matches) {
      const winW =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      return winW;
    } else {
      const winW = window.screen.width;
      return winW;
    }
  };

  return (
    <ContentView2>
      <ContainerPortfolio id="portfolio">
        <TextView>Nosso Portifólio</TextView>
        <ButtonPrimary
          onClick={() => {
            const data = pdfFile;
            const link = document.createElement("a");

            if (typeof link.download === "string") {
              link.href = data;
              link.download = `portifolio_griztechsolar.pdf`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              window.open(data);
            }
          }}
        >
          Baixar Portifólio <IconArrowBtn />
        </ButtonPrimary>

        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={getWidth() <= 600 ? 1 : getWidth() <= 1255 ? 2 : 3}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {[
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            img7,
            img8,
            img9,
            img10,
            img11,
            img12,
            img13,
            img14,
            img15,
            img16,
            img17,
            img18,
            img19,
            img20,
            img21,
          ]?.map((img, i) => (
            <SwiperSlide>
              <img src={img} alt={`Portifólio img ${i}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </ContainerPortfolio>
    </ContentView2>
  );
};

export default Portfolio;
