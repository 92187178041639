import styled, { keyframes } from "styled-components";

export const ContainerAbout = styled.div`
  display: flex;
  flex-direction: column;
  backdrop-filter: saturate(180%) blur(20px);
  @media screen and (max-width: 640px) {
    backdrop-filter: initial;
  }
`;
export const SolutionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -105px;
  /* gap: 4.5%; */
  gap: 25px;
  > div:nth-child(1) {
    margin-left: 0;
  }
  > div:nth-last-child(1) {
    margin-right: 0;
  }
  @media screen and (max-width: 1335px) {
    gap: 10px;
  }
  @media screen and (max-width: 915px) {
    margin-top: -60px;
    gap: 8px;
  }
  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
    /* gap: 25px; */
  }
  @media screen and (max-width: 640px) {
    margin-top: 0;
    gap: 20px;
  }
`;

export const icon = keyframes`
	0% {
        transform: scale(1.0);
	}
	50% {
        transform: scale(1.05);
	}
	100% {
        transform: scale(1.0);
	}
`;
export const transformtop = keyframes`
	0% {
        transform: translateY(0);
	}
	50% {
        transform: translateY(-10px);
	}
	100% {
        transform: translateY(0);
	}
`;
export const rotate = keyframes`
	0% {
        transform: rotate(0deg);
	}
	50% {
        transform: rotate(360deg);
	}
	100% {
        transform: rotate(0deg);
	}
`;
export const rotate2 = keyframes`
	0% {
        transform: rotate(0deg);
	}
	40% {
        transform: rotate(-30deg);
	}
	80% {
        transform: rotate(30deg);
	}
    100% {
        transform: rotate(0deg);
    }
`;
export const rotate3 = keyframes`
	0% {
        transform: rotate(0deg);
	}
	40% {
        transform: rotate(10deg);
	}
	80% {
        transform: rotate(-10deg);
	}
    100% {
        transform: rotate(0deg);
    }
`;

export const ContentAbout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0;

  > section {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 1335px) {
      gap: 10px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 915px) {
      gap: 8px;
      margin-bottom: 8px;
    }
    @media screen and (max-width: 640px) {
      flex-direction: column;
      gap: 15px;
      margin-top: 10px;
    }
    .area-text {
      display: flex;
      flex-direction: column;
      flex: 1;
      > p {
        color: var(--color-text-2);
        text-transform: uppercase;
        font-size: 14px;
      }
      > h2 {
        width: 90%;
        margin-top: 5px;
        font-size: 32px;
        font-weight: 700;
        color: var(--color-text-1);
        > b {
          color: var(--color-for);
          font-weight: 900;
        }
        > strong {
          color: var(--color-third);
          font-weight: 800;
        }
      }
      @media screen and (max-width: 1335px) {
        > p {
          font-size: 12px;
        }
        > h2 {
          font-size: 25px;
        }
      }
      @media screen and (max-width: 915px) {
        > p {
          font-size: 11px;
        }
        > h2 {
          font-size: 21px;
        }
      }
      @media screen and (max-width: 750px) {
        > p {
          font-size: 10px;
        }
        > h2 {
          font-size: 17px;
        }
      }
      @media screen and (max-width: 640px) {
        > p {
          font-size: 11px;
        }
        > h2 {
          font-size: 18px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .icon-world {
    animation: ${rotate} 8s ease-in-out infinite;
  }
  .icon-solar path:nth-child(4) {
    transform-origin: 50% 50%;
    animation: ${rotate2} 5s ease-in-out infinite;
  }
  .icon-energy path:nth-child(1) {
    transform-origin: 50% 50%;
    fill: var(--color-primary);
    animation: ${rotate3} 5s ease-in-out infinite;
  }
  .icon-home {
    animation: ${transformtop} 5s ease-in-out infinite;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 2;
  }
  .icon-home path:nth-child(2) {
    fill: var(--color-primary);
  }
  .icon-home:hover {
    animation: ${rotate} 5s ease-in-out infinite;
  }
`;

export const CardInfo = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  gap: 10px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  height: 350px;
  border-radius: 20px;
  padding: 32px;
  > h1 {
    font-size: 26px;
    font-weight: 700;
    color: var(--color-second);
  }
  > p {
    font-size: 15px;
    color: var(--color-text-2);
  }
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    > h5 {
      font-size: 18px;
      text-align: left;
      font-weight: 500;
      width: 90%;
      color: var(--color-text-2);
    }
    > a {
      margin-top: auto !important;
    }
  }
  > svg {
    width: 105px;
    height: 105px;
    margin-left: auto;
    margin-top: auto;
    > path,
    > ellipse {
      fill: var(--color-second-50);
      stroke: var(--color-second);
    }
  }
  > img {
    width: 200px;
    margin-left: auto;
    margin-top: auto;
    z-index: 1;
  }
  > svg * {
    animation: ${icon} 3s ease infinite;
  }
  > svg > path:nth-child(4) {
    stroke: var(--color-primary);
  }

  > svg *:hover {
    transform: scale(1.05);
  }
  > img:hover {
    transform: rotate(-10deg) scale(1.1);
  }
  &:hover {
    /* transform: scale(1.05); */
  }
  @media screen and (max-width: 1335px) {
    gap: 8px;
    height: 330px;
    padding: 28px;
    > h1 {
      font-size: 24px;
    }
    > p {
      font-size: 14px;
    }
    > div {
      > h5 {
        font-size: 15px;
      }
    }
    > svg {
      width: 90px;
      height: 90px;
    }
    > img {
      width: 150px;
    }
  }
  @media screen and (max-width: 915px) {
    gap: 5px;
    height: 290px;
    padding: 23px;
    > h1 {
      font-size: 22px;
    }
    > p {
      font-size: 12px;
    }
    > div {
      > h5 {
        font-size: 13px;
      }
    }
    > svg {
      width: 70px;
      height: 70px;
    }
    > img {
      width: 120px;
    }
    .icon-home {
      width: 30px;
      height: 30px;
    }
  }
  @media screen and (max-width: 750px) {
    height: 235px;
    padding: 18px;
    gap: 2px;
    > h1 {
      font-size: 17px;
    }
    > p {
      font-size: 11px;
    }
    > div {
      > h5 {
        font-size: 11px;
      }
    }
    > svg {
      width: 60px;
      height: 60px;
    }
    > img {
      width: 100px;
    }
    .icon-home {
      width: 25px;
      height: 25px;
    }
  }
  @media screen and (max-width: 640px) {
    height: 350px;
    padding: 20px;
    gap: 5px;
    > h1 {
      font-size: 18px;
    }
    > p {
      font-size: 12px;
    }
    > div {
      > h5 {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    > svg {
      width: 80px;
      height: 80px;
    }
    > img {
      width: 120px;
    }
    .icon-home {
      width: 25px;
      height: 25px;
    }
  }
`;
