import React from "react";
import { ButtonPrimary } from "../Buttons/ButtonPrimary";
import { IconArrowBtn } from "../../layouts/Header";
import { SolutionsContentItem } from "../../views/Solutions/style";
import { Solution } from "../../views/About";
import { onSolicitarOrcamento } from "../../providers/infos";

const ItemSolution = ({ activeSolution }: { activeSolution: Solution }) => {
  const solution_titles = {
    "Energia Solar Residencial":
      "Vamos simplificar o processo para você adotar a energia solar!",
    "Energia Solar para Condomínio":
      "Transforme seu condomínio com energia solar de forma simples",
    "Energia Solar Empresarial":
      "Transforme sua empresa com energia solar de forma descomplicada:",
    "Energia Solar Para Agronegócios":
      "Prepare-se para uma transformação solar completa:",
  };

  const solutionName = activeSolution.name as keyof typeof solution_titles;

  const replacements = {
    "Energia Solar para Condomínio": ["casa", "condomínio"],
    "Energia Solar Empresarial": ["casa", "empresa"],
    "Energia Solar Para Agronegócios": ["casa", "propiedade"],
  };

  const solutionNameReplacements =
    activeSolution.name as keyof typeof replacements;

  const replaceWords = (text: string): string => {
    return text?.replaceAll(
      replacements?.[solutionNameReplacements]?.[0],
      replacements?.[solutionNameReplacements]?.[1]
    );
  };

  const solution_infos = {
    title: solution_titles[solutionName] || "Energia Solar Residencial",

    howitsworks: [
      {
        step: "Análise personalizada",
        description:
          "Usamos sua conta de luz para propor equipamentos que se adequem ao seu consumo.",
      },
      {
        step: "Visita especializada",
        description: "Avaliamos sua casa para garantir as melhores condições.",
      },
      {
        step: "Facilitamos o crédito",
        description: "Pesquisamos as linhas ideais para otimizar seus custos.",
      },
      {
        step: "Projeto seguro e homologado",
        description:
          "Desenvolvemos seu projeto conforme normas de segurança e com aprovação da concessionária.",
      },
      {
        step: "Troca sem estresse",
        description:
          "Cuidamos de todo o processo de troca do medidor para o bidirecional.",
      },
      {
        step: "Iniciação suave",
        description:
          "Agendamos a visita da concessionária para iniciar seu sistema fotovoltaico e configuramos o monitoramento de captação e consumo.",
      },
    ],
  };
  return (
    <SolutionsContentItem className="back-primary">
      <h2>Como Funciona?</h2>
      <p>{solution_infos?.title}</p>

      <section>
        {solution_infos.howitsworks?.map((step, i) => (
          <div>
            <article>{i + 1}</article>
            <section>
              <h5>{step?.step}</h5>
              <p>{replaceWords(step?.description || "")}</p>
            </section>
          </div>
        ))}
      </section>

      <p>
        Agende já sua avaliação <b>gratuita!</b>
      </p>
      <ButtonPrimary onClick={() => onSolicitarOrcamento()}>
        Avaliação Gratuita <IconArrowBtn />
      </ButtonPrimary>
    </SolutionsContentItem>
  );
};

export default ItemSolution;
