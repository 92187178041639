import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        --color-primary: #FBBF0B;
        --color-primary-50: #fbbf0b4e;
        --color-primary-20: #fbbf0b16;
        --color-second: #00B5EE;
        --color-second-2: #0a6da3;
        --color-second-50: #00b6ee57;
        --color-third: #FF5B06;
        --color-third-50: #ff590648;
        --color-for: #44AB48;
        --color-for-50: #44ab4853;
        --text-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
        --box-shadow: 0px 20px 45px 35px rgba(150, 150, 150, 0.13);

        --color-text-1: #101113;
        --color-text-2: #3A3A3A;
        --color-text-3: #b9b9b9;

        --padding-page: 0 14%;
    }
    @media screen and (max-width: 1495px) {
        :root {
            --padding-page: 0 4%;
        }
    }
    @media screen and (max-width: 1335px) {
        :root {
            --padding-page: 0 3%;
        }
    }
    @media screen and (max-width: 490px) {
        :root {
            --padding-page: 0 5%;
        }
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        /* font-family: 'Inter', sans-serif; */
        /* font-family: 'Poppins', sans-serif; */
        transition: all 0.5s cubic-bezier(0.28, 0.12, 0.32, 1);
        /* transition: all 0.2s ease; */
        scroll-behavior: smooth;
    }
    .hover-text:hover{
        text-decoration: underline;
    }
    .hover-back-primary, .back-primary{
        position: relative;
    }
    .hover-back-primary::before, .back-primary::before {
        content: '';
        border-radius: 22px;
        background-color: var(--color-third);
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        transform: rotate(0deg);
        z-index: -1;
        transition: all 0.6s ease;
        width: 100%;
    }
    .hover-back-primary:hover::before, .back-primary::before {
        top: -10px;
        left: -10px;
        transform: rotate(-3deg);
    }
    .hover-back-primary::after, .back-primary::after {
        content: '';
        border-radius: 22px;
        background-color: var(--color-primary);
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        transform: rotate(0deg);
        z-index: -1;
        transition: all 0.4s ease;
        width: 100%;
    }
    .hover-back-primary:hover::after, .back-primary::after {
        top: 7px;
        left: 7px;
        transform: rotate(-3deg);
    }

   /*  */

    .hover-back-second, .back-second{
        position: relative;
    }
    .hover-back-second::before, .back-second::before {
        content: '';
        border-radius: 22px;
        background-color: var(--color-second);
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        transform: rotate(0deg);
        z-index: -1;
        transition: all 0.6s ease;
        width: 100%;
    }
    .hover-back-second:hover::before, .back-second::before {
        top: -10px;
        left: -10px;
        transform: rotate(5deg);
    }
    .hover-back-second::after, .back-second::after {
        content: '';
        border-radius: 22px;
        background-color: var(--color-second-2);
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        transform: rotate(0deg);
        z-index: -1;
        transition: all 0.4s ease;
        width: 100%;
    }
    .hover-back-second:hover::after, .back-second::after {
        top: 10px;
        left: 10px;
        transform: rotate(-5deg);
    }
`;

export default GlobalStyle;
