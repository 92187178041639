import styled, { keyframes } from "styled-components";
import { rotate2, rotate3 } from "../../views/About/styles";

export const ContainerBanner = styled.div`
  width: 100%;
  height: 842px;
  @media screen and (max-width: 1335px) {
    height: 800px;
  }
  @media screen and (max-width: 915px) {
    height: 550px;
  }
  @media screen and (max-width: 750px) {
    height: 500px;
  }
  @media screen and (max-width: 640px) {
    height: 660px;
  }
  overflow: hidden;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 842px;
  @media screen and (max-width: 1335px) {
    height: 800px;
  }
  @media screen and (max-width: 915px) {
    height: 550px;
  }
  @media screen and (max-width: 750px) {
    height: 500px;
  }
  @media screen and (max-width: 640px) {
    height: 660px;
    object-fit: fill;
    object-position: left -30px;
  }
  @media screen and (max-width: 490px) {
    object-position: left -15px;
  }
  object-fit: cover;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-box-reflect: below 0px
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
`;

export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    padding-top: 50px;
  }
`;

export const BannerLogo = styled.img`
  margin-left: 6%;
  @media screen and (max-width: 1335px) {
    margin-left: 3%;
    width: 16%;
  }
  @media screen and (max-width: 915px) {
    margin-left: 4%;
    width: 15%;
  }
  @media screen and (max-width: 640px) {
    margin: 0 auto;
    width: 15%;
    margin-bottom: 9%;
  }
  @media screen and (max-width: 670px) {
    margin: 0 auto;
    width: 20%;
    margin-bottom: 10%;
  }
  @media screen and (max-width: 490px) {
    width: 22%;
    margin-bottom: 11%;
  }
`;

export const BannerAreaText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10%;
  .big {
    font-size: 65px;
  }
  @media screen and (max-width: 1335px) {
    margin-left: 6%;
    .big {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 915px) {
    margin-left: 8%;
    .big {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 640px) {
    margin-left: 0;
    margin: 0 auto;
    width: 100%;
    height: fit-content;
    height: 100%;
    padding: 20px 6%;
    .big {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 470px) {
    padding: 20px 5%;
  }
`;

export const TextBanner2 = styled.h2`
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  color: #f6f6f6;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 1335px) {
    font-size: 40px;
  }
  @media screen and (max-width: 915px) {
    font-size: 25px;
    line-height: 50px;
  }
  @media screen and (max-width: 640px) {
    font-size: 22px;
    line-height: 58px;
  }
`;

export const rotatetext = keyframes`
	0% {
    color: var(--color-primary);
    transform: scale(0.50) rotate(0deg);
    opacity: 0;
  }
  25% {
    color: var(--color-second);
    transform: scale(1.05) rotate(2deg);
    opacity: 1;
  }
  50% {
    color: var(--color-second);
    transform: scale(1) rotate(-2deg);
    opacity: 1;
  }
  100% {
    color: var(--color-second);
    transform: scale(1);
    opacity: 1;
  }
`;

export const TextBanner1 = styled.h1`
  color: var(--color-second);
  text-shadow: 0px 0px 5px var(--color-primary), 0px 5px var(--color-primary),
    4px 0 var(--color-primary), -4px 0 var(--color-primary),
    0 4px var(--color-primary), 0 -4px var(--color-primary),
    4px 4px var(--color-primary), -4px -4px var(--color-primary),
    4px -4px var(--color-primary), -4px 4px var(--color-primary);
  font-size: 102px;
  font-weight: 700;
  line-height: 113px;
  position: relative;
  width: fit-content;
  &::after {
    content: "Até 95%";
    text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25),
      0px 8px 8px rgba(0, 0, 0, 0.25), 3px 0 rgba(0, 0, 0, 0.25),
      -3px 0 rgba(0, 0, 0, 0.25), 0 3px rgba(0, 0, 0, 0.25),
      0 -3px rgba(0, 0, 0, 0.25), 3px 3px rgba(0, 0, 0, 0.25),
      -3px -3px rgba(0, 0, 0, 0.25), 3px -3px rgba(0, 0, 0, 0.25),
      -3px 3px rgba(0, 0, 0, 0.25);
    position: absolute;
    color: var(--color-primary);
    left: 0px;
    z-index: -1;
  }
  transform-origin: 50% 50%;
  animation: ${rotatetext} 3s ease infinite;
  @media screen and (max-width: 1335px) {
    font-size: 85px;
    line-height: 90px;
  }
  @media screen and (max-width: 915px) {
    font-size: 65px;
    line-height: 50px;
  }
  @media screen and (max-width: 640px) {
    color: var(--color-primary) !important;
    font-size: 55px;
    line-height: 42px;
    text-shadow: 0px 0px 3px var(--color-second), 0px 3px var(--color-second),
      3px 0 var(--color-second), -3px 0 var(--color-second),
      0 3px var(--color-second), 0 -3px var(--color-second),
      3px 3px var(--color-second), -3px -3px var(--color-second),
      3px -3px var(--color-second), -3px 3px var(--color-second);
    &::after {
      text-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25),
        0px 5px 5px rgba(0, 0, 0, 0.25), 3px 0 rgba(0, 0, 0, 0.25),
        -3px 0 rgba(0, 0, 0, 0.25), 0 3px rgba(0, 0, 0, 0.25),
        0 -3px rgba(0, 0, 0, 0.25), 3px 3px rgba(0, 0, 0, 0.25),
        -3px -3px rgba(0, 0, 0, 0.25), 3px -3px rgba(0, 0, 0, 0.25),
        -3px 3px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const gradientlinear = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const ContainerText = styled.section`
  font-size: 28px;
  padding: 15px 20px;
  border-radius: 20px;
  font-weight: 400;
  /* background-color: #fff; 
  color: #101113; */
  /* background-color: rgba(240, 80, 2, 0.5); */
  /* background: linear-gradient(180deg,rgba(240, 80, 2, 0.5), rgba(240, 80, 2, 1)); */
  background: linear-gradient(
    10deg,
    rgba(240, 115, 2, 0.8),
    rgba(240, 115, 2, 0.4),
    rgba(240, 115, 2, 0.8),
    rgba(240, 115, 2, 0.4)
  );
  background-size: 400% 400%;
  animation: ${gradientlinear} 10s ease infinite;
  backdrop-filter: saturate(180%) blur(20px);
  color: #fff;
  box-shadow: var(--text-shadow);
  width: 80%;
  margin-top: 25px;
  margin-bottom: 40px;
  > b {
    font-weight: 700;
  }
  @media screen and (max-width: 1335px) {
    width: 90%;
    font-size: 22px;
    padding: 12px 18px;
    margin-top: 20px;
    margin-bottom: 35px;
  }
  @media screen and (max-width: 915px) {
    width: 95%;
    font-size: 17px;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    font-size: 14px;
    padding: 10px 15px;
    margin-top: 5px;
    margin-bottom: 20px;
    background: linear-gradient(
      10deg,
      rgba(0, 181, 238, 0.9),
      rgba(0, 181, 238, 0.4),
      rgba(0, 181, 238, 0.9),
      rgba(0, 181, 238, 0.4)
    );
    animation: ${gradientlinear} 15s ease infinite;
    background-size: 400% 400%;
  }
  @media screen and (max-width: 470px) {
    margin-bottom: 15px;
  }
`;

export const BannerFooter = styled.div`
  display: flex;
  align-items: center;
  width: 79%;
  justify-content: space-between;
  > button {
    padding: 0 30px;
    padding-right: 22px;
    font-size: 15px;
    height: 50px;
  }
  @media screen and (max-width: 640px) {
    > button {
      background: linear-gradient(
        90deg,
        var(--color-second),
        var(--color-second-2),
        var(--color-second),
        var(--color-second-2)
      );
      animation: ${gradientlinear} 15s ease infinite;
      background-size: 400% 400%;
    }
  }
  @media screen and (max-width: 915px) {
    width: 95%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  @media screen and (max-width: 470px) {
    flex-wrap: wrap;
    gap: 12px;
    > button {
      padding: 0 22px !important;
      padding-right: 10px !important;
      font-size: 12px !important;
      height: 42px !important;
    }
  }
`;

export const AreaFinanciament = styled.section`
  /* background: linear-gradient(
    10deg,
    rgba(240, 115, 2, 0.8),
    rgba(240, 115, 2, 0.4),
    rgba(240, 115, 2, 0.8),
    rgba(240, 115, 2, 0.4)
  ); */
  background: #fff;
  /* background-size: 400% 400%; */
  /* animation: ${gradientlinear} 10s ease infinite; */
  /* backdrop-filter: saturate(180%) blur(20px); */
  box-shadow: var(--text-shadow);
  border-radius: 20px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  > p {
    color: var(--color-text-2);
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 7px;
  }
  > img {
    width: 400px;
  }
  @media screen and (max-width: 1335px) {
    > p {
      font-size: 14px;
    }
    > img {
      width: 300px;
    }
  }
  @media screen and (max-width: 915px) {
    padding: 10px 15px;

    > p {
      font-size: 11px;
    }
    > img {
      width: 230px;
    }
  }
  @media screen and (max-width: 640px) {
    background: #fff;
    > p {
      font-size: 11px;
      color: var(--color-text-1);
    }
    > img {
      width: 200px;
    }
  }
`;
