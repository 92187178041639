import styled from "styled-components";
import { SolutionsContent } from "../About/styles";

export const ContainerSolutions = styled.div`
  margin: 80px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  > button {
    margin: 20px 0;
  }
`;
export const SolutionsContentButtons = styled(SolutionsContent)`
  margin-top: 50px;
`;
export const SolutionsContentItem = styled.div`
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  /* border: 3px solid var(--color-third-50); */
  width: 100%;
  border-radius: 25px;
  padding: 30px 35px;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  > h2 {
    color: var(--color-text-1);
    font-size: 28px;
  }
  > p {
    color: var(--color-text-3);
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
    > b {
      color: var(--color-text-2);
    }
  }
  > section {
    margin: 15px 0;
    > div {
      display: flex;
      align-items: center;
      margin: 15px 0;
      > article {
        width: 42px;
        height: 42px;
        background-color: var(--color-third-50);
        /* border: 2px solid var(--color-second-50); */
        color: var(--color-third);
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 12px;
      }
      > section {
        display: flex;
        flex-direction: column;
        > h5 {
          font-size: 16px;
          font-weight: 700;
          color: var(--color-text-2);
        }
        > p {
          color: var(--color-text-3);
          margin-top: 2px;
        }
      }
    }
  }
  > button {
    margin-left: auto;
    padding: 0 30px;
    padding-right: 22px;
    font-size: 15px;
    height: 50px;
  }
  @media screen and (max-width: 1335px) {
    padding: 25px 30px;
    > h2 {
      font-size: 25px;
    }
    > p {
      font-size: 16px;
      margin: 8px 0;
    }
    > section {
      margin: 12px 0;
      > div {
        margin: 12px 0;
        > article {
          width: 38px;
          height: 38px;
          font-size: 18px;
          margin-right: 10px;
        }
        > section {
          > h5 {
            font-size: 14px;
          }
          > p {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 915px) {
    padding: 20px 25px;
    > h2 {
      font-size: 22px;
    }
    > p {
      font-size: 14px;
      margin: 5px 0;
    }
    > section {
      margin: 5px 0;
      > div {
        margin: 10px 0;
        > article {
          min-width: 38px;
          min-height: 38px;
          font-size: 17px;
          margin-right: 10px;
        }
        > section {
          > p {
            font-size: 13px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 18px 22px;
    > h2 {
      font-size: 21px;
    }
    > p {
      font-size: 13px;
      margin: 3px 0;
    }
    > section {
      margin: 5px 0;
      > div {
        margin: 8px 0;
        > article {
          min-width: 35px;
          min-height: 35px;
          width: 35px;
          height: 35px;
          font-size: 16px;
          margin-right: 10px;
        }
        > section {
          > p {
            font-size: 13px;
          }
          > h5 {
            font-size: 13px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 490px) {
    padding: 15px 20px;
    > h2 {
      font-size: 20px;
    }
    > p {
      font-size: 12px;
      margin: 2px 0;
    }
    > section {
      margin: 5px 0;
      > div {
        margin: 8px 0;
        > article {
          min-width: 32px;
          min-height: 32px;
          width: 32px;
          height: 32px;
          font-size: 15px;
          margin-right: 10px;
        }
        > section {
          > p {
            font-size: 12px;
          }
          > h5 {
            font-size: 12px;
          }
        }
      }
    }
    > button {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 640px) {
    width: 92%;
  }
`;
