import React, { useState, useEffect } from "react";
import { ContentView } from "../../styles";
import {
  ContainerStepsConstruction,
  Step,
  StepContent,
  StepPercentHeight,
  TextView,
} from "./style";

interface StepInterface {
  id: number;
  title?: string;
}

const StepsConstruction = () => {
  const [steps, setSteps] = useState<StepInterface[]>([
    {
      id: 1,
      title: "Proposta comercial e análise de consumo.",
    },
    {
      id: 2,
      title: "Avaliação de crédito e documentação.",
    },
    {
      id: 3,
      title: "Estudo técnico no local de instalação.",
    },
    {
      id: 4,
      title: "Pedido de material.",
    },
    {
      id: 5,
      title: "Assinatura do contrato.",
    },
    {
      id: 6,
      title: "Processo de homologação e envio do projeto à distribuidora.",
    },
    {
      id: 7,
      title: "Montagem dos equipamentos.",
    },
    {
      id: 8,
      title: "Troca do medidor.",
    },
    {
      id: 9,
      title: "Configuração do aplicativo para acompanhamento em tempo real.",
    },
    {
      id: 10,
      title:
        "Visitas técnicas de rotina para garantir o funcionamento e a satisfação do cliente.",
    },
    {
      id: 11,
    },
  ]);

  const [stepActive, setStepActive] = useState<StepInterface>();
  const [heightStep, setHeightStep] = useState(0);

  // const [scrollPosition, setScrollPosition] = useState(0);
  const [lastPosition, setLastPosition] = useState(0);

  const element = document.getElementById("stepcontent");

  const handleScroll = () => {
    const position = window.pageYOffset;
    const scrollPosition = position;

    if (element) {
      const getposition = Math.round(
        element.getBoundingClientRect().top + document.documentElement.scrollTop
      );

      if (scrollPosition >= getposition - 600) {
        if (scrollPosition > lastPosition) {
          if (heightStep <= 100) {
            setHeightStep((step) => step + 1);
          }
        } else if (scrollPosition < lastPosition) {
          if (heightStep >= 0) {
            setHeightStep((step) => step - 1);
          }
        }

        setLastPosition(scrollPosition);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [element, lastPosition, heightStep]);

  const checkActiveStep = (id: number) => heightStep + 10 >= id * 10;

  return (
    <ContentView>
      <ContainerStepsConstruction>
        <TextView>Etapas do inicio a construção do Projeto</TextView>

        <StepContent id="stepcontent">
          <StepPercentHeight>
            <div style={{ height: `${heightStep}%` }}></div>
          </StepPercentHeight>
          {steps?.map((step) =>
            steps?.length === step?.id ? (
              <Step
                className={checkActiveStep(step?.id) ? "step active" : "step"}
              >
                <section>
                  <div>
                    <IconCheck />
                  </div>
                </section>
              </Step>
            ) : (
              <Step
                className={checkActiveStep(step?.id) ? "step active" : "step"}
              >
                <section>
                  <div>{step?.id}</div>
                  <p className={step?.id % 2 === 0 ? "left" : "right"}>
                    {step?.title}
                  </p>
                </section>
              </Step>
            )
          )}
        </StepContent>
      </ContainerStepsConstruction>
    </ContentView>
  );
};

export default StepsConstruction;

export const IconCheck = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 14L8.5 17.5L19 6.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
