import React, { useEffect, useRef, useState } from "react";
import {
  AreaFinanciament,
  BannerAreaText,
  BannerContent,
  BannerFooter,
  BannerImage,
  BannerLogo,
  ContainerBanner,
  ContainerText,
  TextBanner1,
  TextBanner2,
} from "./styles";
import { IconArrowBtn } from "../Header";
import banner from "../../assets/images/banner/banner.png";
import bannerresponsive from "../../assets/images/banner/bannerresponsive.png";
import logo from "../../assets/images/logos/vertical_logo.png";
import financiamentos from "../../assets/images/banner/financeiro.png";
import { ButtonPrimary } from "../../components/Buttons/ButtonPrimary";
import { onSolicitarOrcamento } from "../../providers/infos";

const Banner = () => {
  return (
    <ContainerBanner>
      {window.innerWidth <= 640 ? (
        <BannerImage src={bannerresponsive} alt="Banner" />
      ) : (
        <BannerImage src={banner} alt="Banner" />
      )}

      <BannerContent>
        <BannerLogo src={logo} alt="Banner Logo" />

        <BannerAreaText>
          <TextBanner2>Economize até</TextBanner2>
          <TextBanner1>Até 95%</TextBanner1>
          <TextBanner2 className="big">em sua conta de energia!</TextBanner2>

          <ContainerText>
            <b>Reduza os custos</b> com energia. Solicite um orçamento sem
            compromisso e economize <b>hoje!</b>
          </ContainerText>

          <BannerFooter>
            <AreaFinanciament>
              <p>Trabalhamos com Financiamento</p>
              <img src={financiamentos} alt="Financiamentos" />
            </AreaFinanciament>
            <ButtonPrimary onClick={() => onSolicitarOrcamento()}>
              Solicite um orçamento <IconArrowBtn />
            </ButtonPrimary>
          </BannerFooter>
        </BannerAreaText>
      </BannerContent>
    </ContainerBanner>
  );
};

export default Banner;
