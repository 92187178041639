import React, { useRef, useState } from "react";
import white_logo from "../../assets/images/logos/white_logo.png";
import { LinkButton } from "../../components/Buttons/LinkButton";
import {
  AreaButtonClose,
  ButtonMenu,
  ContainerHeader,
  Logo,
  MenuArea,
  NavLinks,
} from "./styles";
import { ButtonPrimary } from "../../components/Buttons/ButtonPrimary";
import { nav, onSolicitarOrcamento } from "../../providers/infos";
import { Flex } from "../../styles";
import { useClickAway } from "@uidotdev/usehooks";

export interface HeaderTypes {
  activeLink: number;
  setActiveLink: React.Dispatch<React.SetStateAction<number>>;
}

const Header = ({ activeLink, setActiveLink }: HeaderTypes) => {
  const [activeMenu, setActiveMenu] = useState<boolean>(false);

  const menuRef = useClickAway(() => {
    setActiveMenu(false);
  });

  const toggleMenu = () => setActiveMenu(!activeMenu);

  return (
    <ContainerHeader>
      <Logo href="">
        <img src={white_logo} alt="Griz Tech's Logo" title="Griz Tech's Logo" />
      </Logo>

      <NavLinks className="show-out-responsive-750">
        {nav.map((link) => (
          <LinkButton
            id={`${link?.id}`}
            to={link?.to}
            className={activeLink === link?.id ? "active" : ""}
            onClick={() => {
              setActiveLink(link?.id);
              window.scrollTo({ top: 0 });
            }}
            spy={true}
            smooth={true}
            offset={-120}
            delay={0}
            duration={20}
          >
            {link?.name}

            {activeLink === link?.id ? <section></section> : ""}
          </LinkButton>
        ))}
      </NavLinks>

      <Flex>
        <ButtonPrimary onClick={() => onSolicitarOrcamento()}>
          Solicite um orçamento <IconArrowBtn />
        </ButtonPrimary>

        {/* Area Reponsive */}
        {activeMenu ? (
          <ButtonMenu className="show-in-responsive-750">
            <IconMenu
              stylepath1={{
                transform: activeMenu
                  ? "rotate(-45deg) translateY(6px) translateX(-12px)"
                  : "rotate(0deg)",
              }}
              stylepath2={{
                transform: activeMenu
                  ? "rotate(45deg) translateY(-16px) translateX(3px)"
                  : "rotate(0deg)",
              }}
            />
          </ButtonMenu>
        ) : (
          <ButtonMenu onClick={toggleMenu} className="show-in-responsive-750">
            <IconMenu
              stylepath1={{
                transform: activeMenu
                  ? "rotate(-45deg) translateY(6px) translateX(-12px)"
                  : "rotate(0deg)",
              }}
              stylepath2={{
                transform: activeMenu
                  ? "rotate(45deg) translateY(-16px) translateX(3px)"
                  : "rotate(0deg)",
              }}
            />
          </ButtonMenu>
        )}

        <AreaButtonClose
          style={{
            opacity: activeMenu ? 1 : 0,
            visibility: activeMenu ? "visible" : "hidden",
            right: activeMenu ? "3%" : -200,
          }}
          className="show-in-responsive-750"
        >
          <ButtonMenu className="show-in-responsive-750">
            <IconMenu
              stylepath1={{
                transition: "all 0.6s ease",
                transform: activeMenu
                  ? "rotate(-45deg) translateY(6px) translateX(-12px)"
                  : "rotate(0deg)",
              }}
              stylepath2={{
                transition: "all 0.6s ease",
                transform: activeMenu
                  ? "rotate(45deg) translateY(-16px) translateX(3px)"
                  : "rotate(0deg)",
              }}
            />
          </ButtonMenu>
        </AreaButtonClose>

        <MenuArea
          ref={menuRef as React.MutableRefObject<HTMLDivElement | null>}
          style={{
            opacity: activeMenu ? 1 : 0,
            visibility: activeMenu ? "visible" : "hidden",
            right: activeMenu ? "3%" : -200,
          }}
          className="show-in-responsive-750"
        >
          <NavLinks className="nav-responsive flex-column">
            {nav.map((link) => (
              <LinkButton
                id={`${link?.id}`}
                to={link?.to}
                className={activeLink === link?.id ? "active" : ""}
                onClick={() => {
                  setActiveLink(link?.id);
                  window.scrollTo({ top: 0 });
                }}
                spy={true}
                smooth={true}
                offset={-120}
                delay={0}
                duration={20}
              >
                {link?.name}

                {activeLink === link?.id ? <section></section> : ""}
              </LinkButton>
            ))}
          </NavLinks>
        </MenuArea>
      </Flex>
    </ContainerHeader>
  );
};

export default Header;

interface TypeIcon {
  stylepath1?: React.CSSProperties | undefined;
  stylepath2?: React.CSSProperties | undefined;
}

const IconMenu = ({ stylepath1, stylepath2, ...a }: TypeIcon) => (
  <svg
    {...a}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={stylepath1}
      d="M4 8.5L20 8.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      style={stylepath2}
      d="M4 15.5L20 15.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const IconArrowBtn = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 18L14.2929 12.7071C14.6262 12.3738 14.7929 12.2071 14.7929 12C14.7929 11.7929 14.6262 11.6262 14.2929 11.2929L9 6"
      stroke="#101113"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
