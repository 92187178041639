import React, { useState, useEffect } from "react";
import Banner from "./layouts/Banner";
import Header from "./layouts/Header";
import { PageContainer } from "./styles";
import GlobalStyle from "./styles/GlobalStyle";
import About from "./views/About";
import Solutions from "./views/Solutions";
import StepsConstruction from "./views/StepsConstruction";
import { Vantagens } from "./views/Vantagens";
import { Solution } from "./views/About";
import { solutions } from "./views/Solutions/mock";
import Portfolio from "./views/Portfolio";
import Footer, { IconWhatsApp } from "./layouts/Footer";
import { useWindowScrollPositions } from "./providers/useWindowScrollPositions";
import { linkWhatsapp2 } from "./providers/infos";
import { IconButtonBottom } from "./components/Buttons/IconButtonBottom";

function App() {
  const [activeSolution, setActiveSolution] = useState<Solution>(solutions[0]);
  const [activeLink, setActiveLink] = useState<number>(0);
  const [top, setTop] = useState<boolean>(false);
  const { scrollY } = useWindowScrollPositions();

  useEffect(() => {
    setActiveSolution(solutions[0]);
  }, []);

  useEffect(() => {
    if (scrollY > 300) {
      setTop(true);
    } else {
      setTop(false);
    }
  }, [scrollY]);

  return (
    <>
      <GlobalStyle />

      <PageContainer>
        <Header activeLink={activeLink} setActiveLink={setActiveLink} />
        <Banner />

        <About
          activeSolution={activeSolution}
          setActiveSolution={setActiveSolution}
        />

        <Vantagens />

        <StepsConstruction />

        <Solutions
          activeSolution={activeSolution}
          setActiveSolution={setActiveSolution}
        />

        <Portfolio />

        <Footer activeLink={activeLink} setActiveLink={setActiveLink} />

        <IconButtonBottom
          onClick={() => window.open(linkWhatsapp2)}
          style={{ bottom: 25 }}
          className="whats"
        >
          <p>Contate-nos</p>
          <IconWhatsApp />
        </IconButtonBottom>
        <IconButtonBottom
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
          style={{ bottom: top ? 85 : -200 }}
        >
          <IconArrowUp />
        </IconButtonBottom>
      </PageContainer>
    </>
  );
}

export default App;

const IconArrowUp = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15L11.2929 9.70711C11.6262 9.37377 11.7929 9.20711 12 9.20711C12.2071 9.20711 12.3738 9.37377 12.7071 9.70711L18 15"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
