import styled from "styled-components";
import { Link } from "react-scroll";

export const LinkButton = styled(Link)`
  font-size: 18px;
  font-weight: 700;
  color: #f6f6f6;
  white-space: nowrap;
  margin: 0 15px;
  cursor: pointer;
  &.button {
    color: var(--color-primary);
    display: flex;
    align-items: center;
    margin: 0;
    > svg path {
      stroke: var(--color-primary);
    }
    &:active svg {
      transform: translateX(5px);
    }
  }
  &.button:hover {
    color: var(--color-primary);
    text-shadow: var(--text-shadow);
    transform: scale(1);
  }
  .active {
  }
  &:hover {
    color: #fff;
    text-shadow: var(--text-shadow);
    transform: scale(1.12);
  }
  &:active {
    transform: scale(1);
  }
  @media screen and (max-width: 1335px) {
    font-size: 15px;
    margin: 0 15px;
  }
  @media screen and (max-width: 915px) {
    font-size: 13px;
    margin: 0 8px;
  }
`;
